<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("messages.callback.redirect") }}</h1>
    </vca-card>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CallbackView",
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  created() {
    this.$store.dispatch({ type: "callback", data: this.code }).finally(() => {
      this.$router.push({ path: this.$store.getters["user/loginCallback"] });
    });
  },
};
</script>
